const moment = require('moment')

const calcDateDiff = (firstDate, lastDate, unit = "days") => {
    console.log(firstDate, lastDate)
    const diff = moment(firstDate).diff(lastDate, unit)
    return diff
}

module.exports = {
    calcDateDiff
}