const formatDate = (dt, config = {}) => {
    const {
        extensive = false,
        language = "pt-br"
    } = config

    let data = new Date(dt)

    let day = data.getDate()
    let month = data.getMonth()
    let year = data.getFullYear()

    if (day < 10) day = `0${day}`

    let monthsLocales = {
        "pt-br": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        "en": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "es": ["Enero", "Febrero", "Marcha", "Abril", "Mayo", "Junio", "Mes de Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    }

    let months = monthsLocales[language] ? monthsLocales[language] : monthsLocales["pt-br"]

    if (!extensive) return `${day}/${month < 10 ? `0${month}` : month}/${months}`
    return `${day} de ${months[month - 1]} de ${year}`
}

const formatDateTime = (datetime, format) => {
    return moment(datetime).format(format)
}

const formatReal = (value) => {
    if (typeof value == "string") value = +value
    if (value == 0) return "R$ 0,00"
    else if (!value) return
    value = value + 0.0
    //return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    if (typeof value === 'number') {
        const [currency, cents] = value.toFixed(2).toString().split('.');

        return `R$ ${currency.replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${cents}`;
    }

    return 'R$ 0,00';
}

const formatCPF = (cpf) => {
    if (!cpf || cpf.length != 11) return cpf
    return `${cpf[0]}${cpf[1]}${cpf[2]}.${cpf[3]}${cpf[4]}${cpf[5]}.${cpf[6]}${cpf[7]}${cpf[8]}-${cpf[9]}${cpf[10]}`
}

const formatCPFCNPJ = (doc) => {
    if (![11, 14].includes(doc.length))
        return doc

    if (doc.length == 11)
        return doc.substr(0, 3) + "." + doc.substr(3, 3) + "." + doc.substr(6, 3) + "-" + doc.substr(9, 2)
    return doc.substr(0, 2) + "." + doc.substr(2, 3) + "." + doc.substr(5, 3) + "/" + doc.substr(8, 4) + "-" + doc.substr(12, 2)
}

const formatBRPhone = (number) => {
    if (!number) return number
    let ddi = null
    if (number.length > 11) {
        ddi = number.substring(0, 2)
        number = number.replace(ddi, "")
    }
    let ddd = number.substring(0, 2)
    let nine = number.length == 11 ? number.substring(2, 3) : ""
    let first = number.length == 11 ? number.substring(3, 7) : number.substring(2, 6)
    let second = number.length == 11 ? number.substring(7, 11) : number.substring(6, 10)

    return `${ddi ? `+${ddi} ` : ""}(${ddd}) ${nine}${first}-${second}`
}

module.exports = {
    formatDate,
    formatDateTime,
    formatReal,
    formatCPF,
    formatCPFCNPJ,
    formatBRPhone
}